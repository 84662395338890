<template>
	<v-row align="center" justify="center">
		<v-col md="8">
			<v-card>
				<v-card-title>
					{{ $t('crudActions.' + (id !== null ? 'updateModel' : 'createModel'), { model: $t('models.accountableEntities.single') }) }}
				</v-card-title>
				<v-card-text>
					<validation-observer ref="validationObserver" slim>
						<accountable-entity-form :accountable-entity="accountableEntity" :entity-types="entityTypes" :zones="zones" :validations="validationRules"></accountable-entity-form>
					</validation-observer>
					<v-card-actions>
						<v-btn
							color="primary"
							@click="$router.push({ name: $routes.accountableEntity.indexRoute.name })">
							{{ $t('actions.back_to_list') }}
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="success" @click="createOrUpdateAccountableEntity">
							{{ $t('crudActions.' + (id !== null ? 'update' : 'create')) }}
						</v-btn>
					</v-card-actions>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { createAccountableEntity, updateAccountableEntity, fetchAccountableEntity, getParams } from "@/api/accountableEntity.js";
import { resetData } from "../../utils/ComponentHelper";
import AccountableEntityForm from "../../components/accountableEntity/AccountableEntityForm";
import ContentLoader from "../../mixins/ContentLoader.js";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";
import _ from 'lodash';

function data() {
	return {
		accountableEntity: {
			name: null,
			code: null,
			entityType: null,
			zoneId: null
		},
		entityTypes: [],
		zones: []
	};
}

export default {
	name: "AccountableEntityCreateOrUpdate",
	components: {
		AccountableEntityForm,
		ValidationObserver,
	},
	mixins: [ContentLoader, Validatable],
	props: {
		id: {
			type: Number,
			required: false,
			default: null
		}
	},
	data,
	watch: {
		'$route' () {
			this.loadFormData();
		},
		'$store.state.lang' () {
			this.fetchParams();
		}
	},
	computed: {
		validationRules() {
			return {
				name: "required",
				code: "required",
				entityType: "required",
				zoneId: "required",
			};
		},
	},
	created () {
		this.loadFormData();
	},
	methods: {
		async createOrUpdateAccountableEntity() {
			let accEntityPromise;

			const { validationObserver } = this.$refs,
				isValid = await validationObserver.validate();

			if (isValid) {
				if(this.id !== null) {
					accEntityPromise = updateAccountableEntity(this.id, this.accountableEntity);
				} else {
					accEntityPromise = createAccountableEntity(this.accountableEntity);
				}

				accEntityPromise.then(() => {
					this.$router.push({ name: this.$routes.accountableEntity.indexRoute.name });
				}).catch(error => this.handleErrorResponse(validationObserver, error));
			}
		},
		async loadFormData() {
			resetData(this.$data, data);
			this.$startLoading();

			if(typeof this.id === 'number') {
				try {
					const response = await fetchAccountableEntity(this.id);

					this.accountableEntity = response.data.accountableEntity;
					await this.$nextTick();
				} catch (e) {
					console.error(e);
				}
			}

			this.fetchParams();

			this.$finishedLoading();
		},
		fetchParams() {
          return getParams().
            then(response => {
				const { entityTypes, zones } = response.data;
				this.entityTypes = entityTypes;
				this.zones = zones;
            });
        },
	},
};
</script>
