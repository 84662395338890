<template>
  <v-form>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.name"
      slim
      name="name">
      <v-text-field
        v-model="accountableEntity.name"
        :label="$t('attributes.accountableEntities.name')"
        name="name"
        :error-messages="errors"
        type="text" />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="validations.code"
      slim
      name="code">
      <v-text-field
        v-model="accountableEntity.code"
        :label="$t('attributes.accountableEntities.code')"
        name="code"
        :error-messages="errors"
        type="text" />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="validations.entityType"
      slim
      name="entityType">
      <v-autocomplete
        v-model="accountableEntity.entityType"
        :items="entityTypes"
        item-text="name"
        item-value="id"
        :error-messages="errors"
        :label="$t('attributes.accountableEntities.entityType')" />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="validations.zoneId"
      slim
      name="zone">
      <v-autocomplete
        v-model="accountableEntity.zoneId"
        :items="zones"
        item-text="name"
        item-value="id"
        :error-messages="errors"
        :label="$t('attributes.accountableEntities.zone')" />
    </validation-provider>
  </v-form>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "AccountableEntityForm",
  components: {
    ValidationProvider,
  },
  props: {
    accountableEntity: {
      type: Object,
      required: true,
    },
    entityTypes: {
      type: Array,
      required: true,
    },
    zones: {
      type: Array,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
  },
};
</script>
