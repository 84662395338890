var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-provider',{attrs:{"rules":_vm.validations.name,"slim":"","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.accountableEntities.name'),"name":"name","error-messages":errors,"type":"text"},model:{value:(_vm.accountableEntity.name),callback:function ($$v) {_vm.$set(_vm.accountableEntity, "name", $$v)},expression:"accountableEntity.name"}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.code,"slim":"","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.accountableEntities.code'),"name":"code","error-messages":errors,"type":"text"},model:{value:(_vm.accountableEntity.code),callback:function ($$v) {_vm.$set(_vm.accountableEntity, "code", $$v)},expression:"accountableEntity.code"}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.entityType,"slim":"","name":"entityType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.entityTypes,"item-text":"name","item-value":"id","error-messages":errors,"label":_vm.$t('attributes.accountableEntities.entityType')},model:{value:(_vm.accountableEntity.entityType),callback:function ($$v) {_vm.$set(_vm.accountableEntity, "entityType", $$v)},expression:"accountableEntity.entityType"}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.zoneId,"slim":"","name":"zone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.zones,"item-text":"name","item-value":"id","error-messages":errors,"label":_vm.$t('attributes.accountableEntities.zone')},model:{value:(_vm.accountableEntity.zoneId),callback:function ($$v) {_vm.$set(_vm.accountableEntity, "zoneId", $$v)},expression:"accountableEntity.zoneId"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }